import React , { useState, useEffect, useCallback } from 'react';

import { FaArrowRight , FaArrowLeft , FaTimes , FaRegEnvelope, FaLock } from 'react-icons/fa';
import useMedia from 'use-media';
import { useClickOutside } from 'react-click-outside-hook';
import { reactLocalStorage } from 'reactjs-localstorage';

import FormStyle from './FormStyle';
import api from '../../actions/api';
import Step1 from './Step-1/Step-1';
import Step2 from './Step-2/Step-2';
import Step3 from './Step-3/Step-3';
import Step4 from './Step-4/Step-4';
import Steps from '../../components/Steps/Steps';
import { isEmpty, dateToString } from '../../actions/functions';
import CreateAccount from '../CreateAccount/CreateAccount';
import { defaultCountry, viewSd } from '../../actions/variables';

const maxSteps = 4;
const stepsLabel = ['Dane podstawowe','Dodaj zdjęcia','Sposób dostarczenia / Opis','Finalizacja'];

let saveDataStorage = null;

const Form = ({ setShowForm , showForm , Notify , userData , userToken, setDataComment }) => {

    const [name,setName] = useState('');
    const [surname,setSurname] = useState('');
    const [phone,setPhone] = useState('48');
    const [email,setEmail] = useState('');
    const [street,setStreet] = useState('');
    const [house,setHouse] = useState('');
    const [houseNr,setHouseNr] = useState('');
    const [city,setCity] = useState('');
    const [country,setCountry] = useState(defaultCountry);
    const [code,setCode] = useState('');
    const [firm,setFirm] = useState('');
    const [date,setDate] = useState(null);
    const [addrUps,setAddrUps] = useState('');
    const [toners,setToners] = useState('full');
    const isResponsive = useMedia({maxWidth: viewSd+'px'});

    const [defaultProducts] = useState([ 
        { firm: '', nr: '', c: '', cat: 0 , loading: false, photos: [] }, 
        { firm: '', nr: '', c: '', cat: 0, loading: false, photos: [] }, 
        { firm: '', nr: '', c: '', cat: 0, loading: false, photos: [] },
        { firm: '', nr: '', c: '', cat: 0, loading: false, photos: [] },
        { firm: '', nr: '', c: '', cat: 0, loading: false, photos: [] }
    ]);
    const [products,setProducts] = useState(defaultProducts);

    const [delivery,setDelivery] = useState(1);
    const [bank,setBank] = useState('');
    const [content,setContent] = useState('');

    const [agreeReg,setAgreeReg] = useState(false);
    const [agreeProc,setAgreeProc] = useState(false);
    const [agreeRodo,setAgreeRodo] = useState(false);
    const [agreeCarefull,setAgreeCarefull] = useState(false);

    // console.log('step: ',step);
    const [step,setStep] = useState(1);
    const [checkStep,setCheckStep] = useState(null);
    const [refOverlay, hasClickedOutsideOverlay] = useClickOutside();
    const [errComm,setErrComm] = useState('');
    const [sending,setSending] = useState(false);

    const [showAddAccount,setShowAddAccount] = useState(false);
    const [editUserData,setEditUserData] = useState(false);
    const [errorsProfile,setErrorsProfile] = useState([]);

    const clearData = () => {
        clearTimeout(saveDataStorage);
        setProducts(defaultProducts);
        setAgreeReg(false);
        setAgreeProc(false);
        setAgreeRodo(false);
        setContent('');
        setAddrUps('');
        setDate(null);
        reactLocalStorage.remove('data_form_'+showForm);
    }

    const readDataBrowser = useCallback((getAll = true) => {
        const dataStorage = reactLocalStorage.getObject('data_form_'+showForm);
        if (!isEmpty(dataStorage)){
            if (getAll){
                setName(dataStorage.name);
                setSurname(dataStorage.surname);
                setPhone(dataStorage.phone);
                setEmail(dataStorage.email);
                setStreet(dataStorage.street);
                setHouse(dataStorage.house);
                setHouseNr(dataStorage.houseNr);
                setCity(dataStorage.city);
                setCountry(dataStorage?.country!==undefined && dataStorage.country!=='' ? dataStorage.country : defaultCountry);
                setCode(dataStorage.code);
                setFirm(dataStorage.firm);
                setBank(dataStorage.bank);
            }

            // setDate(dataStorage.date);
            setAddrUps(dataStorage.addrUps);
            setProducts(dataStorage.products);
            setDelivery(dataStorage.delivery);
            setContent(dataStorage.content);
            setAgreeProc(dataStorage.agreeProc);
            setAgreeReg(dataStorage.agreeReg);
            setAgreeRodo(dataStorage.agreeRodo);
            setToners(dataStorage.toners);
        } else if (getAll){
            if (userData !== null){
                setName(userData.name);
                setSurname(userData.surname);
                setEmail(userData.email);
                setBank(userData.bank_account);
                setCity(userData.city);
                setFirm(userData.firm_name);
                setPhone(userData.phone);
                setStreet(userData.street);
                setHouseNr(userData.houseNr);
                setHouse(userData.street_number);
                setCode(userData.code);
            }
        }
    }, [userData, showForm])

    useEffect(() => {
        if (Boolean(userToken)){
            setSending(true);
            api('getBaseDataUser',userToken,{}, r => {
                if (r.result){
                    setName(r.data.userData.name);
                    setSurname(r.data.userData.surname);
                    setEmail(r.data.userData.email);
                    setBank(r.data.userData.bank_account);
                    setCity(r.data.userData.city);
                    setFirm(r.data.userData.firm_name);
                    setPhone(r.data.userData.phone);
                    setStreet(r.data.userData.street);
                    setHouse(r.data.userData.street_number);
                    setHouseNr(r.data.userData.houseNr);
                    // setHouseNr(r.data.userData.street_number_dep);
                    setCode(r.data.userData.code);
                    readDataBrowser(false);
                } else {
                    readDataBrowser();
                }
                setSending(false);
            });
        } else {
            readDataBrowser();
        }
    },[readDataBrowser, userToken]);

    useEffect(() => {
        if (hasClickedOutsideOverlay){
            setShowForm(false)
        }
    },[hasClickedOutsideOverlay, setShowForm]);

    useEffect(() => {
        if (errComm!==''){
            setTimeout(() => {
                setErrComm('');
            },3000);
        }
    },[errComm]);

    useEffect(() => {
        if (saveDataStorage) clearTimeout(saveDataStorage);
        saveDataStorage = setTimeout(() => {
            reactLocalStorage.setObject('data_form_'+showForm,{ name,surname,phone,email,street,city,house,country,code,firm,date,addrUps,delivery,bank,content,agreeReg,agreeProc,agreeRodo,step,products,toners })
        },3000);
    },[name,surname,phone,email,street,city,house,country,code,firm,date,addrUps,delivery,bank,content,agreeReg,agreeProc,agreeRodo,step,products,toners, showForm]);

    // const checkAccount = () => {
    //     api('checkEmailUserExist','',{ email }, r => {
    //         if (!(r.result && r.data.exist)){
    //             alert('x');
    //         }
    //     });
    // }

    const goStep = (nr) => {
        if (nr!==step && nr<=maxSteps && nr>0){
            const elActual = document.querySelector("#step-"+step);
            const elNext = document.querySelector("#step-"+nr);
            if (nr>step){// forward
                elActual.style.left = '-100%';
                elNext.style.left = 0;
            } else {
                elActual.style.left = '100%';
                elNext.style.left = 0;
            }
            setStep(nr);
            setCheckStep(null);
        }
    }

    const send = (create) => {
        // console.log('sending ...');

        const sendData = {
            name,
            surname,
            phone,
            email,
            street,
            city,
            streetApp: houseNr,
            streetNumber: house,
            country,
            code,
            firm,
            date: dateToString(date),
            addrUps,
            delivery,
            bank,
            content,
            orders: products,
            formType: showForm,
            toners,
            create
        }

        setSending(true);
        api('saveForm',userToken,sendData,r => {
            setSending(false);
            setShowForm(false);
            if (r.result){
                // Notify('Formularz sprzedaży','Formularz został wysłany');
                clearData();
                if (r.data?.userCreate === 0){
                    setDataComment({ header: 'Formularz sprzedaży', buttonClose: true, content: 'Dziękujemy za wypełnienie formularza, został on wysłany i będzie dalej procedowany.' });
                } else if(r.data.userCreate === 2){
                    setDataComment({ header: 'Formularz sprzedaży', buttonClose: true, content: 'Dziękujemy za wypełnienie formularza, został on wysłany i będzie dalej procedowany. Zostałeś również zarejestrowany, dane do logowania powinieneś dostać w e-mailu.' });
                } else if(r.data?.userCreate === 1){
                    setDataComment({ header: 'Formularz sprzedaży', buttonClose: true, content: 'Dziękujemy za wypełnienie formularza, został on wysłany i będzie dalej procedowany. Nie zostałeś zarejestrowany gdyż ten adres e-mail jest już zajęty.' });
                }
            } else {
                Notify('Formularz sprzedaży',r.comm,'error');
            }
        });
    }

    const createAccount = (result) => {
        setShowAddAccount(false);
        send(Number(result));
    }

    const finalOrder = () => {
        if (userData !== null){
            send(0);
        } else {
            setShowAddAccount(true);
        }
    }

    const saveUserData = () => {
        const sendData = {
            name,
            surname,
            phone,
            email,
            street,
            streetNumber: house,
            houseNr: houseNr,
            city,
            house,
            country,
            code,
            firm,
            bank
        }

        setSending(true);
        api('saveUserData',userToken,sendData,r => {
            setSending(false);
            if (r.result){
                Notify('Aktualizacja danych',r.data.comm);
                setEditUserData(false);
                setErrorsProfile([]);
            } else {
                Notify('Aktualizacja danych',r.comm,'error');
                if (r.errors){
                    setErrorsProfile(r.errors);
                } else {
                    setErrorsProfile([]);
                }
            }
        });
    }

    return (
        <FormStyle>
            
            <div className={"overlay"+(sending ? ' loadingLayer' : '')} ref={refOverlay}>
                <header>
                    {'Formularz sprzedaży'} - [ <span>{stepsLabel[step-1]}</span> ]
                    <Steps current={step} maxSteps={maxSteps} classStyle="stepsDots" goStep={goStep} setCheckStep={setCheckStep} />
                    {errComm !== '' && <div className="errComm">{errComm}</div>}
                </header>
                {showAddAccount && <CreateAccount email={email} callBack={createAccount} />}
                {!showAddAccount &&
                    <>
                        <div className="steps">

                            <div className="step step-1" id="step-1">

                                <Step1 
                                    name={name} 
                                    setName={setName} 
                                    surname={surname} 
                                    setSurname={setSurname} 
                                    phone={phone} 
                                    setPhone={setPhone} 
                                    email={email} 
                                    setEmail={setEmail} 
                                    street={street}
                                    setStreet={setStreet}
                                    house={house}
                                    setHouse={setHouse}
                                    city={city}
                                    setCity={setCity}
                                    country={country}
                                    setCountry={setCountry}
                                    code={code}
                                    setCode={setCode}
                                    firm={firm}
                                    setFirm={setFirm}
                                    date={date}
                                    setDate={setDate}
                                    check={checkStep === 1}
                                    setCheckStep={setCheckStep}
                                    goStep={goStep}
                                    bank={bank}
                                    setBank={setBank}
                                    // isLogin={Boolean(userToken)}
                                    blockDataProfile={Boolean(userToken) && !editUserData}
                                    errorsProfile={errorsProfile}
                                    houseNr={houseNr}
                                    setHouseNr={setHouseNr}
                                />
                                
                            </div>
                            <div className="step step-2" id="step-2">
                                <Step2 
                                    check={checkStep === 2} 
                                    goStep={goStep} 
                                    setCheckStep={setCheckStep} 
                                    setErrComm={setErrComm} 
                                    products={products} 
                                    setProducts={setProducts} 
                                    Notify={Notify} 
                                    toners={toners}                            
                                    setToners={setToners}
                                />
                            </div>
                            <div className="step step-3" id="step-3">
                                <Step3 
                                    delivery={delivery} 
                                    setDelivery={setDelivery} 
                                    content={content} 
                                    setContent={setContent} 
                                    check={checkStep === 3} 
                                    goStep={goStep} 
                                    setCheckStep={setCheckStep} 
                                    addrUps={addrUps}
                                    setAddrUps={setAddrUps}
                                />
                            </div>
                            <div className="step step-4" id="step-4">
                                <Step4 
                                    agreeReg={agreeReg} 
                                    setAgreeReg={setAgreeReg} 
                                    agreeProc={agreeProc} 
                                    setAgreeProc={setAgreeProc} 
                                    agreeRodo={agreeRodo} 
                                    setAgreeRodo={setAgreeRodo} 
                                    check={checkStep === 4} 
                                    goStep={goStep} 
                                    setCheckStep={setCheckStep} 
                                    setErrComm={setErrComm} 
                                    send={finalOrder}
                                    agreeCarefull={agreeCarefull}
                                    setAgreeCarefull={setAgreeCarefull}
                                    delivery={delivery}
                                />
                            </div>
                        </div>
                        <div className="buttons">
                            {step>1 &&
                                <button className="backword" onClick={() => goStep(step-1)}><FaArrowLeft /> Cofnij</button>
                            }
                            {(step<maxSteps && !editUserData) &&
                                <button className="forward" onClick={() => setCheckStep(step)}>Dalej <FaArrowRight /> </button>
                            }
                            {step === maxSteps &&
                                <button className={"final"+(!(agreeReg && agreeProc && agreeRodo && (delivery !== 0 ? agreeCarefull : true)) ? ' disabled' : '')} disabled={!(agreeReg && agreeProc && agreeRodo && (delivery !== 0 ? agreeCarefull : true))} onClick={() => setCheckStep(step)}>Wyślij <FaRegEnvelope /> </button>
                            }
                            {!(isResponsive && editUserData) && <button className="close" onClick={() => setShowForm(false)}><FaTimes /> Zamknij</button>}
                            {(step === 1 && Boolean(userToken)) &&
                                (!editUserData ? 
                                    <button className="unlock" onClick={() => setEditUserData(true)}><FaLock /><span> Odblokuj edycję danych</span></button>
                                    :
                                    <>
                                        <button className="editCancel" onClick={() => setEditUserData(false)}><FaTimes /> Anuluj</button>
                                        <button className="saveProfile" onClick={() => saveUserData()}><FaTimes /> Zapisz zmiany</button>
                                    </>
                                )
                            }
                        </div>
                    </>
                }
            </div>
        </FormStyle>
    );
}
export default Form;